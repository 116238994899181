<template>
  <NuxtLink
    v-if="isLoggedIn"
    :to="localePath('checkout/cart')"
  >
    <UiIcon name="cart" />
    <ClientOnly>
      <span
        v-if="cartItemsCount > 0"
        class="rounded-full bg-blue-150 p-0.5 px-1.5 text-sm font-bold text-white"
      >{{ cartItemsCount }}</span>
    </ClientOnly>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useCustomer } from '@customer/composables/useCustomer'

const { localePath } = useT3Utils()
const { isLoggedIn, customerData } = useCustomer()

const cartItemsCount = computed(() => customerData.value?.cart?.summary_count ?? 0)
</script>
